import React from 'react'

import classes from './MenuButton.module.css';

const MenuButton = (props) => {

    return (
        <div className={classes.MenuButtonWrapper}>
            <button
                type="button"
                name="MenuButton"
                onClick={props.handler} >
                <div className={classes.HorizontalLineWrapper}>
                    <div className={classes.HorizontalLine}></div>
                    <div className={classes.HorizontalLine}></div>
                    <div className={classes.HorizontalLine}></div>
                </div>
            </button>
        </div>
    );
}

export default MenuButton;
