import React from 'react';
import classes from './TextPhoto.module.css';
import image from '../assets/img/andrew_malmo_square.jpg';

const TextPhoto = (props) => {
    let mdash = String.fromCharCode(0x2014);
    let deiStatement = <>
        <h3 name="diversity" id="diversity">Diversity, Equity, and Inclusion</h3>
        <p>Equity, inclusion, and diversity are very important to me, and I have always strived to enhance inclusion at my workplace.
            I believe it is vital to keep inclusion in mind when making any design decisions; for example, when using placeholder names in exam questions,
            I advise instructors to use names that reflect the diversity of their student body, rather than the &lsquo;Alice&rsquo;, &lsquo;Bob&rsquo;, &lsquo;Charlie&rsquo;, etc.
            Furthermore, I believe a workplace is inherently bettered by diversity, as it allows a wider perspective when making decisions and helps connect with different groups of people.</p>
        <p> I especially believe in accessibility, which is truly the process of building an inclusive environment for people with disabilities.
            I completed the <a href="http://iadp.ahs.illinois.edu/" target="_blank" rel="noreferrer">Information Accessibility and Design Program (IADP) Professional Certificate</a>, and throughout my career I have strived to make websites and course sites more accessible for all those who have disabilities.</p>
        <p>
            I would like to continue to support efforts for diversity, equity, and inclusion wherever I work, in all regards. Diversity encompasses, but is not limited to: sex, gender, sexual orientation, race, skin color, ethnicity, national origin, age, disability status, socioeconomic background,
            religious beliefs or lack thereof, and life experiences.
            I wish for everyone to have equal access to education, research, and technology, and I work hard to ensure access in whatever I help create.</p>
    </>

    ;
    return (
        <>
            <h2 name="bio" id="bio">Bio</h2>
            <div className={classes.TextPhotoWrapper}>
                <div>
                    <p>
                        My name is Andrew Debevec and I am a bioinformatician and online learning specialist living in Malmö, Sweden. 
                        I am currently participating in a 4-month internship as a Bioinformatician at Lund University, and am seeking full-time employment or a Ph.D. student position.
                    </p>
                    <p>
                        I received my B.S. in Computational Biology & Entomology in 2011 from Cornell University, and my M.S. in Entomology from University of Illinois at Urbana-Champaign in 2014.
                        From January 2015 to July 2023, I worked at the University of Illinois, in educational technology, bioinformatics research support, online learning, IT support, and web development.
                    </p>
                    <p>
                        I moved to Sweden in August 2023 and have spent the time since moving establishing myself here. 
                        I have taken courses in Swedish (and continued to work on it daily since), and finished the legal process of obtaining a residence permit (renewable in 2029).
                    </p>
                    <p>
                        After my internship ends, I hope to continue to pursue bioinformatics. 
                        I have previous experience working with phylogenetic systematics in insects, but would like to focus more on human health in the future.
                        I am very interested in single-cell RNA sequencing, and am open to other areas of bioinformatics as well. 
                    </p>
                    <h3 name="skills" id="skills">Skills</h3>
                    <ul>
                        <li>Computer programming, using Python, R, Ruby, and Lua</li>
                        <li>Bioinformatics, especially phylogenetic systematics</li>
                        <li>Able to learn new skills and technologies quickly</li>
                        <li>Experienced working with diverse, international teams, as well as independently</li>

                        <li>Learning Management System (LMS) management, usage, and education</li> 
                        <li>Front-end web development using JavaScript, React.js, Ruby on Rails, HTML5, and CSS</li>
                        <li>Trained in accessible design, including Certificate of Professional Development in{' '}
                            <a href="http://iadp.ahs.illinois.edu/" target="_blank" rel="noreferrer">Information Accessibility Design and Policy</a>
                            {' '}from the College of Applied Health Sciences at the University of Illinois</li>

                        <li>General IT troubleshooting/support, including networking, Windows and MacOS systems, hardware/other peripheral instruments, Linux administration,
                            and software installation issues (especially Adobe and Microsoft products)</li>
                        <li>Fluent English (first language), intermediate Swedish (actively improving), and intermediate Spanish</li>
                        
                    </ul>
                    {deiStatement}
                </div>
                <img src={image} alt="Andrew wearing a knit hat in Malmö, Sweden" />
            </div>
        </>
    );
}

export default TextPhoto;
