import React from 'react';
import classes from './BigPic.module.css';

const BigPic = (props) => {
    return (
        <div className={classes.BigPicWrapper}>
           <img src={props.image} alt="Andrew with a cheesy grin at Glacier National Park, USA"/>
        </div>
    );
}

export default BigPic;
