import React from 'react';
import MenuItem from './MenuItem';

import classes from './Menu.module.css';


const Menu = (props) => {

    return (
        <div className={classes.MenuWrapper + (props.visible?"":" "+classes.MenuHidden)}>
            <ul>
                <MenuItem itemName="bio" />
                <li>
                    <a href="https://docs.google.com/document/d/1rXu1yiHLK21gtljL7IdvB8JE2EvgUG_zIYyxhx2esSY/edit?usp=sharing"
                        aria-label={"View Andrew Debevec's resume on Google Docs"}
                        target="_blank"
                        rel="noreferrer">
                        c.v.
                    </a>
                </li>
                <MenuItem itemName="projects" />
                <MenuItem itemName="contact" />
            </ul>
        </div>
    );
}

export default Menu;
