import React from 'react';
import classes from './CardFlow.module.css';
import Card from './Card';

const CardFlow = (props) => {
    return (
        <>
            <h2 name="projects" id="projects">Projects</h2>
            <div className={classes.CardFlowWrapper}>
                <Card selection="1" />
                <Card selection="2" />
                <Card selection="3" />
                {
                    //<Card selection="2" />
                }
            </div>
        </>
    );
}

export default CardFlow;
