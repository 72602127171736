import './App.css';
import Header from '../components/Header';
import BigPic from '../components/BigPic';
import TextPhoto from '../components/TextPhoto';
import CardFlow from '../components/CardFlow';
import Contact from '../components/Contact';

import glacier from '../assets/img/andrew_at_glacier_widecrop2.jpg';

function App() {
  return (
    <div className="App">
      <Header />
      <BigPic image={glacier} />
      <TextPhoto />
      <hr/>
      <CardFlow />
      <hr/>
      <Contact />
    </div>
  );
}

export default App;
