import React from 'react';
import classes from './Contact.module.css';
import linkedin from '../assets/img/linkedin.png';

const Contact = (props) => {
    return (<>
        <h2 name="contact" id="contact">Contact</h2>
        <div className={classes.ContactWrapper}>
            <div>
                <p>Questions? Comments? Want to connect or chat?</p>
                <p>Email: <a href="mailto:andrew.debevec@gmail.com">andrew.debevec@gmail.com</a></p>
                <p><a href="https://www.linkedin.com/in/andrew-debevec" target="_blank" rel="noreferrer" className={classes.LinkedInWrapper}><img src={linkedin} alt="" />LinkedIn</a></p>
            </div>
        </div>
    </>
    );
}

export default Contact;
