import React, {useState} from 'react';
import classes from './Header.module.css';

import Menu from './Menu';
import MenuButton from './MenuButton';

const Header = (props) => {
    const [menuVisible, toggleMenuVisibility] = useState(false);

    function handleClick() {
        toggleMenuVisibility(!menuVisible);
    }

    return (<>
        <header className={classes.Header}>
            <span className={classes.LargeText + " " + classes.RainbowText}>
                Andrew Debevec
            </span>
            <MenuButton handler={handleClick} />
            <Menu visible={menuVisible} />
        </header>
        <h1 className={classes.InvisibleHeader}>Andrew Debevec - Bioinformatician in Malmö, Sweden</h1>
    </>
    );
}

export default Header;
