import React from 'react';
import classes from './CardFlow.module.css';
import practicer from '../assets/img/practicer_postcrop.png';
import genetics from '../assets/img/drosophila_cropped.png';
import tarot from '../assets/img/mystic_tarot_gpt.png';
import scrna from '../assets/img/scrna_seq_cropped.png';

const Card = (props) => {
    

    function contentFromSelection(s) {
        switch(parseInt(s)) {
            case 3:
                return {
                    img: practicer,
                    name: "Practicer",
                    description: "Practicer is tool for learning to read music, built using ReactJS.",
                    viewLink: "https://ahd44.github.io/practicer/",
                    codeLink: "https://github.com/ahd44/practicer"
                };
            case 2:
                return {
                    img: genetics,
                    name: "Genetics",
                    description: "Four genetics activities for introductory biology, built using \"vanilla\" JavaScript.",
                    viewLink: "https://www.andrewdebevec.com/genetics/",
                    codeLink: "https://github.com/ahd44/genetics"
                };
            case 9999:
                return {
                    img: tarot,
                    name: "Mystic Tarot GPT",
                    description: "Tarot card readings using a ChatGPT integration, built using ReactJS/TypeScript.",
                    viewLink: "https://tarot.andrewdebevec.com/",
                    codeLink: "https://github.com/ahd44/mystic-tarot-gpt"
                };
            case 1:
                return {
                    img: scrna,
                    name: "scRNA-seq Analysis",
                    description: "A reanalysis of glioblastoma single-cell RNA sequencing data, using Python (scanpy).",
                    viewLink: "https://github.com/ahd44/scrna_seq_glioblastoma/blob/main/Part1_FindingMalignantCells.ipynb",
                    codeLink: "https://github.com/ahd44/scrna_seq_glioblastoma"
                };
            default:
                return <></>;
        }
    }

    let content = contentFromSelection(props.selection);

    return (<div className={classes.CardWrapper}>
        <img src={content.img} alt={"Screenshot of " + (content.name) + " website"} />
        <h3>{content.name}</h3>
        <p>{content.description}</p>
        <div className={classes.LinkContainer}>
            <a
                href={content.viewLink}
                target="_blank"
                rel="noreferrer"
            >View</a>
            <a
                href={content.codeLink}
                target="_blank"
                rel="noreferrer"
            >Code</a>
        </div>
    </div>);
}

export default Card;
